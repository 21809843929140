import { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from '../Hooks/useWindowDimensions';

const PreAssessment = () => {

  const { windowWidth }                   = useWindowDimensions();

  const [searchParams, setSearchParams]   = useSearchParams();
  let navigate                            = useNavigate();

  // useEffect(() => {
	// 	document.body.style.backgroundColor = "#343333";
	// },[]);
  
  const assessmentLink = () => {
    if(!searchParams) {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  return (
    <div className="container pre-assessment">

      {/*<h1>Before You Start</h1>
      <p className="pre-assessment-subheader">For each of the following 21 church benchmarks, choose one of these three responses that accurately reflects your church's current situation.</p>
      <div className="explanation">
      <div className="marker red"></div><p>The benchmark is not in position to activate the Great Commission or multiply Kingdom impact. It is potentially broken or barely functioning to these standards, and demands attention.</p>
      </div>
      <div className="explanation">
      <div className="marker yellow"></div><p>The benchmark affects Great Commission activation and multiplies Kingdom impact but has room for improvement. It is not at its top level of effectiveness, and needs further action to become optimal.</p>
      </div>
      <div className="explanation">
      <div className="marker green"></div><p>The benchmark activates the Great Commission and multiplies Kingdom impact. It is effective and healthy, but might need slight improvement. Ensure it has the resources and attention it needs.</p>
      </div>*/}
      {windowWidth <= 830 ? <h1>Mission Drift in any ministry can be <br /> identified by plotting two key metrics</h1> :
        <><h1>Mission Drift in any ministry can be <br /> identified by plotting two key metrics</h1></>}
        <h2>
          <span>1. Clarity</span> of your Christian mission <br />
          <span>2. Intentionality</span> of safeguarding it
        </h2>
        <p>Answer ten questions about each aspect <br />to check if your ministry is drifting today.</p>
      <Link to={assessmentLink()} className="cta-link">
        <Button className="cta">TAKE THE TEST</Button>
      </Link>
      
    </div>
  )
}


export default PreAssessment;

import { useState, useEffect, useContext } from 'react';

import Select from 'react-select';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button, TextField, MenuItem, CircularProgress } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import '../App.scss';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Online Pastor", label: "Online Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const Details = (props) => {

  const addCtx = useContext(AdditionalQuestionContext);

  let primary                         = '#F22D33';
  let secondary                       = '#2D8D95';
  let passive                         = '#4F4F4F';

  let navigate                        = useNavigate();
  const { id }                        = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = useState(false);
  const [isLoading, setIsLoading]     = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember]       = useState(true);
  const [name, setName]               = useState("");
  const [firstName, setFirstName]     = useState("");
  const [lastName, setLastName]       = useState("");
  const [org, setOrg]                 = useState("");
  const [email, setEmail]             = useState("");
  const [title, setTitle]             = useState("");
  const [jobTitle, setJobTitle]       = useState("");
  // const [jobTitleMember, setJobTitleMember]       = useState("");
  // const [churchName, setChurchName]   = useState("");
  // const [schoolName, setSchoolName]   = useState("");
  const [zipCode, setZipCode]                     = useState("");
  const [selectedValue, setSelectedValue]         = useState();
  const [firstNameError, setFirstNameError]       = useState(false);
  const [lastNameError, setLastNameError]         = useState(false);
  const [orgError, setOrgError]                   = useState(false);
  const [emailError, setEmailError]               = useState(false);
  const [jobTitleError, setJobTitleError]         = useState(false);
  const [zipCodeError, setZipCodeError]           = useState(false);
  // const [churchNameError, setChurchNameError]  = useState(false);
  // const [schoolNameError, setSchoolNameError]     = useState(false);

  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    // document.body.style.backgroundColor = "#343333";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  useEffect(() => {

    if (firstName?.length)  addCtx.addFormDetails((prev) => ({ ...prev, firstName }));
    if (lastName?.length)   addCtx.addFormDetails((prev) => ({ ...prev, lastName  }));
    if (org?.length)        addCtx.addFormDetails((prev) => ({ ...prev, org       }));
    if (email?.length)      addCtx.addFormDetails((prev) => ({ ...prev, email     }));
    if (jobTitle?.length)   addCtx.addFormDetails((prev) => ({ ...prev, jobTitle  }));
    if (zipCode?.length)    addCtx.addFormDetails((prev) => ({ ...prev, zipCode   }));
    // if (churchName?.length) addCtx.addFormDetails((prev) => ({ ...prev, churchName }));

  }, [firstName, lastName, org, email, jobTitle, zipCode]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    if (formDetails?.firstName?.length) setFirstName(formDetails.firstName);
    if (formDetails?.lastName?.length)  setLastName(formDetails.lastName);
    if (formDetails?.org?.length)       setOrg(formDetails.org);
    if (formDetails?.email?.length)     setEmail(formDetails.email);
    if (formDetails?.jobTitle?.length)  setJobTitle(formDetails.jobTitle);
    if (formDetails?.zipCode?.length)   setZipCode(formDetails.zipCode);
    
    // if (formDetails?.jobTitle?.length) {
    //   let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
    //   setSelectedValue(selected);
    //   setJobTitle(selected);
    // }

  }, [])

  console.log("addCtx.formDetails", addCtx.formDetails);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        // setName(data.name);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setOrg(data.org);
        setEmail(data.email);
        setTitle(data.title);
        // setJobTitle("none");
        setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  // const updateName = (event) => {
  //   setName(event.target.value);
  //   checkFormValid();
  // }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }
  
  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateOrg = (event) => {
    setOrg(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // jobTitle with Select drop down list 
  // const updateJobTitle = (selectedOption) => {
  //   // console.log(event.target.value);
  //   // console.log(selectedOption); console.log(selectedOption.value);
  //   setSelectedValue(selectedOption);
  //   setJobTitle(selectedOption.value);
  //   checkFormValid();
  // }
  
  const updateJobTitle = (event) => {
    setJobTitle(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      console.log(name); console.log(title);
      // console.log(jobTitleMember);
      if(firstName && org) {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      // if(name && org && email && emailRegex.test(email) && jobTitle) {
      if(firstName && lastName && email && emailRegex.test(email) && jobTitle && org && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(!firstName) {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName) {
      setLastNameError(true);
      setTimeout(() => { console.log(lastNameError); }, 2000);
    }
    if(!org) {
      setOrgError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(jobTitle == "none" || !jobTitle) {
    // if(jobTitle == "" || !jobTitle) {
     setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(!jobTitle) {
      setJobTitleError(true);
    }
    if(zipCode.length < 4) {
     setZipCodeError(true);
    }
  }

  console.log(firstName, lastName);

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        // "fullName": name?.trim(),
        // "title": title?.trim(),
        "firstName": firstName?.trim(),
        "lastName": lastName?.trim(),
        "email": email?.trim(),
        "jobTitle": jobTitle?.trim(),
        "churchName": org?.trim(),
        "zipCode": zipCode?.trim(),
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        localStorage.removeItem("assessmentAnswers");
        // localStorage.removeItem("preAdditionalAnswers");
        // localStorage.removeItem("additionalAnswers");
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("handleSubmit running");

    if(isMember && firstName && lastName && jobTitle && emailRegex.test(email)) {
      submitToApi();
    } else if(!isMember && firstName && lastName && org && emailRegex.test(email) && jobTitle && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "org") {
      if(orgError) {
        return <div className="error-text">Please confirm your organization.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please enter your job title.</div>
      }
    }
    // if(field == "schoolName") {
    //   if(schoolNameError) {
    //     return <div className="error-text">Please confirm your school name.</div>
    //   }
    // }

    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = passive);
      e.target.closest(".form-detail").querySelector(".input-title").style.color = secondary;
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "org") {
      setOrgError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    // if(field == "schoolName") {
    //   setSchoolNameError(false);
    // }
    // if(field == "title") {
    //   setJobTitleError(false);
    // }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if(isMember) {
      navigate('/assessment/' + id + '/review?member=' + searchParams.get("member"), { replace: true });
    } else {
      navigate('/assessment/' + id + '/review', { replace: true });
    }
  };

  // go to last additional question when you click back on Details page 
  localStorage.setItem("backFromDetailsPage", true);

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': primary}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        {/* <p><span style={{color: primary}}>*</span> indicates required fields</p>
        <div className="bar" style={{ width: '50%', margin: '0 auto'}}>
          <img style={{marginLeft: '92%'}}decoding="async" height="30" src="https://md0.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
          <LinearProgress className="custom-bar" variant="determinate" value="100" />
        </div> */}
        <h1>You're all done!</h1>
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>
        {/* <p className="mandatory-field-text">All fields are required.</p> */}

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>
          
          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)} onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="speter@petra.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Job title<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="CEO" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("jobTitle")}
          </div>

          { !isMember ? (
            <div className="form-detail">
            <p className="input-title">Organization<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Rock Group" value={org} onFocus={(e) => removeHintText("org", e)}  onChange={(e) => {updateOrg(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("org")}
          </div>
          ) : null }
          

          {/* <div className="form-detail">
            <p className="input-title">Job title*<span className="required-field"></span></p> */}

            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            {/* <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle} 
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: passive,
                  primary: secondary,
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} /> */}
            {/* {showHintText("jobTitle")} */}
            {/* </div> */}

          
          {/* {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Hill Crest School" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("schoolName")}
              </div>
            ) : null
          } */}
          
          {!isMember ? <div className="form-detail">
            <p className="input-title">ZIP code<span className="required-field">*</span></p>
            <TextField hiddenLabel 
              type="text" 
              placeholder="11618" 
              className="form-details-text" 
              value={zipCode} 
              onFocus={(e) => removeHintText("zipCode", e)} 
              onChange={(e) => {updateZipCode(e)}} 
              fullWidth variant="standard" size="normal" 
              InputProps={{ disableUnderline: true }} 
              onInput = {(e) => {
                return e.target.value = e.target.value?.toString().slice(0,12)
              }}
            />
            {showHintText("zipCode")}
          </div> : null}


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );
  
}
export default Details;
import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import testimonialImage from "../Assets/Images/testimonial.png";
import waterWaveImage from "../Assets/Images/water-wave.png";
//import quotationImage from "../Assets/Images/quotation.svg";
//import RemoveIcon from '@mui/icons-material/Remove';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWindowDimensions from '../Hooks/useWindowDimensions';
//import ScheduleCallThumbnail from "../Assets/Images/video-thumb-cropped.png"
import { LinearProgress, CircularProgress, Button, Box } from "@mui/material";
//import { Accordion, AccordionDetails, AccordionSummary, Divider, Tab, Tabs, TextField} from "@mui/material";
//import { Add, ExpandMore, Info, Remove } from "@mui/icons-material";
import "../App.scss";
//import BookSection from "../Components/BookSection";

// import prevArrow from "../Assets/Images/prev-arrow.svg";
// import nextArrow from "../Assets/Images/next-arrow.svg";
// import analyzeIcon from '../Assets/Images/insights.svg';
// import alignmentIcon from '../Assets/Images/align.svg';
// import advanceIcon from '../Assets/Images/advance.svg';
import gridImg from "../Assets/Images/grid-image.png";


const Results = (props) => {
  //let navigate                    = useNavigate();
  const { id }                    = useParams();
  const focusCallRef              = useRef();
  
  // console.log("location hash", window.location.hash);
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  //const [isRequesting, setIsRequesting] = useState(false);
  //const [isCallRequested, setIsCallRequested] = useState(false);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  //const [subScore3, setSubScore3] = useState(0);
  //const [subScore4, setSubScore4] = useState(0);
  // const [subScore5, setSubScore5] = useState(0);
  // const [subScore6, setSubScore6] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);
  const [sortedScores, setSortedScores] = useState([]);

  //const [toggleIndividual, setToggleIndividual]		= useState(0);
  //const [toggleSection, setToggleSection]		= useState(1);
	//const [toggleMore, setToggleMore] 				= useState(0);	
	const [sections, setSections]   					= useState([]);
  const [assessmentInfo, setAssessmentInfo] = useState([]);
  const [answers, setAnswers]               = useState([]);
  const [questions, setQuestions]           = useState([]);
  //const [tabValue, setTabValue]             = useState(0);

  {/*const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box className="inner-tab-box" sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  } */}

  // scroll to call section if url has #call location hash 
  useEffect(() => {
    function scrollToFocus() {
      if (window.location.hash === "#call") {
        focusCallRef?.current?.scrollIntoView({ behavior: "smooth" });
        focusCallRef?.current?.focus();
      }
    }
    let timer = setTimeout(() => {
      scrollToFocus();
    }, 1500);

    return () => clearTimeout(timer);
  }, [focusCallRef]);


  // console.log("answers", answers);
  // console.log("sections", sections);
  // console.log("questions", questions);

  useEffect(() => {
    // document.body.style.backgroundColor = "#343333";
    fetchResults();
  }, []);

  // useEffect(() => {
  //   if (isCallRequested) {
  //     let url = "/call-request-success/" + id;
  //     console.log(url);

  //     window.open(url, "_blank");
  //   }
  // }, [id, isCallRequested]);

  // to animate subScores on results page 
  useEffect(() => {

    let sorted = [{ name: "CLARITY", score: subScore1 }, { name: "INTENTIONALITY", score: subScore2 }];
    // sorted?.sort((a, b) => {
    //   return parseInt(b.score) - parseInt(a.score);
    // })
    setSortedScores(sorted?.sort((a, b) => parseInt(b.score) - parseInt(a.score)));

    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2])
  

  // console.log("sortedScores", sortedScores);



  const fetchResults = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setName(data.name);
          setScore(data.score);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          // setSubScore5(data.subScore5);
          // setSubScore6(data.subScore6);
          setIsMember(data.isTeamMember);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });

      setIsLoading(true);
      fetch(process.env.REACT_APP_API_URI + "/v1/assessment/dashboard/" + id, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            console.log(data);
            setAssessmentInfo(data.assessmentInfo);
            setAnswers(data.assessmentInfo[0].answers);
            setQuestions(data.questionInfo);
            setSections(data.sectionInfo);
            setIsLoading(false);
          } else {
            // setOpenSnackbar(true);
          }
        });
  };

  {/*const requestCall = () => {

    setIsRequesting(true);
    const newWindowReference = window.open();

    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/request-call", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({uniqueId: id, assessmentId: process.env.REACT_APP_CLIENT_ID})
    })
    .then(response => response.json())
    .then((data) => {
      setIsRequesting(false);
      if (data.status === "success") {
        setIsCallRequested(true);
        console.log(data);
        let url = "/call-request-success/" + id;
        console.log(url);
        newWindowReference.location = url;
        // window.open(url, "_blank")
      } else {
          newWindowReference.close();
          setIsCallRequested(false);
          setIsRequesting(false);
        // setOpenSnackbar(true);
      }
    }).catch(err => {
        console.error("POST req error\n", err);
        newWindowReference.close();
        setIsCallRequested(false);
        setIsRequesting(false);
    })
  }*/}

  
  const highestScore = () => {
    let scoresObj = { "clarity": subScore1, "intentionality": subScore2, };
    // console.log("highest val", Object.keys(scoresObj).reduce((a, b) => scoresObj[a] > scoresObj[b] ? a : b ));
    return Object.keys(scoresObj).reduce((a, b) => scoresObj[a] > scoresObj[b] ? a : b);
  }

  const showTitleText = () => {
    if(score < 31) return <h2>{name}, you may have drifted away from your original mission.</h2>;
    else if(score > 30 && score < 71) return <h2>{name}, you might be drifting away from your original mission.</h2>;
    else if(score > 70) return <h2>{name}, you're on course toward remaining Mission True.</h2>;
  };


  const showScoreText = () => {
    if(score < 31) return <div className="result-text"><p>But, don't be discouraged! By taking intentional steps to redefine and reinforce your original mission, you can once again begin to correct course and champion the mission God has given you.</p></div>;
    else if(score > 30 && score < 71) return <div className="result-text"><p>The calling was probably clear for you on day 1. However, if you don't check for drift frequently you can very easily end up far from that calling. Don't be dismayed! You can still correct course to champion the mission God has given you.
    </p></div>;
    else if(score > 70) return <div className="result-text"><p>But, we encourage you to remain watchful! Drift can set in when you least expect it. Continuing to define, protect, and champion the Mission God has given you can help you ensure you remain Mission true.</p></div>;
  };

  // const showProgressBarText = (scoreValue) => {
  //   if (scoreValue >= 95) {
  //     return "Enjoying health";
  //   } else if (scoreValue >= 80 && scoreValue < 95) {
  //     return "Improving health";
  //   } else if (scoreValue >= 70 && scoreValue < 80) {
  //     return "Pursuing health";
  //   } else if (scoreValue < 70) {
  //     return "Battling unhealthiness";
  //   }
  // }

  {/*const showRequestCall = () => {
    return (
      <div className="request-call">
        <h3 ref={focusCallRef}>Talk with the Intentional Churches team</h3>
        <p>Discuss the right next steps for you and your team as you continue your ChurchOS Journey!</p>
        <div className="cta-btn-container">
          <div className="cta-btn">
            <Link to="#">
              { isCallRequested ? 
                <><Button disabled>DONE</Button></> : <>{ isRequesting ?
                  <Button>REQUEST A CALL</Button> :
                  <Button onClick={requestCall}>REQUEST A CALL</Button>
                }</>
              }
            </Link>
          </div>
          
          { windowWidth > 830 && <div className="tooltip">
            <Info className="call-disclaimer-info-icon" />
            <span className="tooltiptext">Clicking this button will submit your call request.</span>
          </div> }

        </div>
        { windowWidth <= 830 && <p className="call-disclaimer"><span>*</span>Please note that clicking this button will submit your call request.</p> }

      </div>
    )
  }*/}

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">


        <div className="invite">
        <h2 className="invite-header">Invite your team to take this assessment</h2>
        <img width="auto" height="40" src="https://md0.bleat.church/wp-content/uploads/2021/06/group.png" className="attachment-large size-large" alt="" loading="lazy" />
          <p>Invite your team members to take this assessment along with you and gain insights into your collective alignment!</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
            </div>
            
            <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
            </div>
            
            <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
            </div>
          </div> */}

          <div className="cta-invite-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE YOUR TEAM</Button>
            </Link>
          </div>

        </div>
      </div>
    }
  };

  {/*const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };*/}

  {/*const getLeftMargin = () => {
    if (windowWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };*/}

  {/*const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }*/}

  {/*const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="thumbnail"><img src={ScheduleCallThumbnail} alt="" srcSet="" /></div>
          <h2>Schedule a free call with Alan</h2>
          Schedule a call with Alan to debrief your test results and take the next step towards supercharging your digital ministry strategy!
          <div className="cta-btn">
            <a
              href="https://calendly.com/reachalangeorge"
              target="_blank"
            >
              <Button>TALK TO ALAN</Button>
            </a>
          </div>
        </div>
      );
    }
  };*/}

  {/*const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Gain new insights into your team's alignment with your school board by inviting them to take this test!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE MY BOARD</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };*/}

  // for combining question, answer, section name all in one array for score wise red, yellow, green accordions 
  // const questionsAnswersCombined = questions?.map(question => {
  //   let currentAnswer = answers?.find(ans => ans.question_id === question.id);
  //   return ({ ...question, chosenAnswer: currentAnswer?.answer });
  // })
  // const queAnsAndSections = questionsAnswersCombined?.map(qa => {
  //   let currentSection = sections?.find(sec => sec.id === qa.sectionId);
  //   return ({ ...qa, sectionId: currentSection?.id, sectionName: currentSection?.section });
  // })
  // // red, yellow, green respectively 
  // const scoreWiseAnswers = [ [], [], [] ];
  
  // for (let i = 0; i < queAnsAndSections.length; i++) {
  //   if(queAnsAndSections[i].chosenAnswer === 1) {
  //     scoreWiseAnswers[0].push(queAnsAndSections[i]);
  //   } else if (queAnsAndSections[i].chosenAnswer === 3) {
  //     scoreWiseAnswers[1].push(queAnsAndSections[i]);
  //   } else if (queAnsAndSections[i].chosenAnswer === 6) {
  //     scoreWiseAnswers[2].push(queAnsAndSections[i]);
  //   }
  // }

  // console.log(queAnsAndSections);
  // console.log(scoreWiseAnswers);

  {/*const accordionColor = (num) => {
    switch (num) {
      case 0:
        return "accordion red";
      case 1:
        return "accordion yellow";
      case 2:
        return "accordion green";
      default:
        return "accordion"
    }
  }*/}

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F22D33" }} size={60} />
      </div>
    );
  }

  // clear localStorage backFromDetailsPage 
  localStorage.removeItem("backFromDetailsPage");

  return (
    <>
      <div className="container results">
        <div className="details-container">

          

          {/*<div className="switch-bars-text">

          
            <div className="results-bars">*/}

              {/*<div className="category-bars">
                
                 <div className="category-bar">
                  <p>STRONGLY CONFIDENT</p>
                  <LinearProgress value={subScore1} variant="determinate" className="progress-bar" />
                </div>
                <div className="category-bar">
                  <p>PRETTY CONFIDENT</p>
                  <LinearProgress value={subScore2} variant="determinate" className="progress-bar" />
                </div>
                <div className="category-bar">
                  <p>QUESTIONING</p>
                  <LinearProgress value={subScore3} variant="determinate" className="progress-bar" />
                </div>
                <div className="category-bar">
                  <p>DOUBTING</p>
                  <LinearProgress value={subScore4} variant="determinate" className="progress-bar" />
                </div> 
                
                {sortedScores?.map((score, index) => {
                  let classesProgressbar = index === 0 ? "highest progress-bar" : "progress-bar";
                  let classesBarName = index === 0 ? "highest bar-name" : "bar-name";
                  let leftPx = windowWidth < 831 ? "35px" : "45px";
                  return (
                    <div className="category-bar">
                      <p className={classesBarName}>{ windowWidth < 831 ? index + 1 + '. \xa0\xa0\xa0\xa0' : ""}{score.name}</p>
                      <div className="progress-bar-percent" style={{ position: "relative",  }}>
                        <p className="percent-text" style={{ left: `calc(${score.score}% - ${leftPx})` }}>{score.score}%</p>
                        <LinearProgress value={score.score/2} variant="determinate" className={classesProgressbar} />
                      </div>
                    </div>
                  );
                })}

              </div>*/}
              
              <div className="flex-container">
                <div className="flex-image">
                  <div className="graph-img">
                    <span className="up_arrow"></span>
                    <img className="img-1" src={gridImg} alt="Workplace" useMap="#ass_result" />
                    {/* <div className="dot" style={{left:`${subScore2}%`,bottom:`${subScore1 / 100 * 85 + 9}%`}}></div> */}
                    {/* 
                    // ========================================================================
                    // left => 0% = 3px & 100% = 309px, so left = (score * 3.06px) + 3px where 3.06 = 309-3/100 
                    // bottom => 0% = 10px & 100% = 317px so bottom = (score * 3.07px) + 10px where 3.07 = 317-10/100 
                    // position should be absolute for this to work properly across all dots  */}
                    <div className="dot" style={{left:`${(subScore2 * 3.06) + 3}px`, bottom:`${(subScore1 * 3.07) + 10}px`}}></div>
                    <span className="right_arrow"></span>
                  </div>
                  
                </div>
                <div className="flex-text">
                  {showTitleText()}
                  <div className="score-text-container">
                    {/* {sortedScores?.map((score) => {
                      return (
                          <span className='score-text'>{score.name} : {score.score}%</span>
                      )})} */}
                    <span className="score-text">CLARITY : {subScore1}%</span>
                    <span className="score-text">INTENTIONALITY : {subScore2}%</span>
                  </div>
                  {showScoreText()}
                </div>    
              </div>
            {/*</div>*/}

          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          

          {/*</div>*/}

          {/* <div className="areas-for-growth">
            <h2>Evaluate areas for growth in your church</h2>
            {scoreWiseAnswers?.map((answer, index) => {
              return (
                  <Accordion key={index} className={accordionColor(index)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {
                      index === 0 ? <p className="white-text">{scoreWiseAnswers[0].length} areas that are broken</p> : index === 1 ? <p className="white-text">{scoreWiseAnswers[1].length} areas you need improvement in</p> : index === 2 ? <p className="white-text">{scoreWiseAnswers[2].length} areas where you're activating impact</p> : null
                    }
                  </AccordionSummary>
                  {answer?.map((score, index) => {
                    return (
                      <Fragment key={index}>
                      <AccordionDetails>
                        <p><strong className="section-name">{score?.sectionName?.toLowerCase()}:</strong> {score.question}</p>
                      </AccordionDetails>
                      <Divider />
                      </Fragment>
                    )
                  })}
                </Accordion>
              )})
            }
          </div> */}

          {/* <div className="score-section-container">

            <div className="score-section">
              <p className="score-txt">{subScores[0]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[0]} /></div>
              <p className="score-name">LEADERSHIP</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[1]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[1]} /></div>
              <p className="score-name">SYSTEMS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[2]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[2]} /></div>
              <p className="score-name">COMMUNICATION</p>
            </div>            

            <div className="score-section">
              <p className="score-txt">{subScores[3]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[3]} /></div>
              <p className="score-name">DATA</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[4]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[4]} /></div>
              <p className="score-name">VOLUNTEERS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[5]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[5]} /></div>
              <p className="score-name">COMMUNITY</p>
            </div>

          </div> */}

        </div>
      </div>

      {showInvite()}

      {/* {showTeamMember()} */}

      {!isMember && <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assement really made a huge difference to the way we saw our ministry and where it was heading. Thanks to this we were able to change course and avoid being completely Mission untrue.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>

          </div>

          <div className="avatar">
            <img src={testimonialImage} title="testimonial" alt="testimonial person"/>
          </div>

          <div className="designation">
            Mike Ross<br />
            {windowWidth <= 830 ? 
              (<><p>Associate at Pearson Hardman</p></>) : (<span>Associate at Pearson Hardman</span>)}
          </div>

        </div>

      </div>}

      {/* <div className="next-steps">
        <h2>Next steps</h2>
        <p className="subheader">Thank you for being intentional about evaluating your church. By investing time into thinking things through, you've taken a crucial step toward self-discovery.</p>

        <Box sx={{ width: '100%' }}>
          <Box>
          <Tabs className="tabs-container"
            TabIndicatorProps={{style: { backgroundColor: 'transparent' }}}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            textColor="secondary"
            // indicatorColor="#DC4A37"
            indicatorColor="primary"
          >
            <Tab className="tab" label="FOR YOU" style={{ minWidth: "49.5%" }} {...a11yProps(0)} />
            <Tab className="tab" label="FOR TEAMS" style={{ minWidth: "49.5%" }} {...a11yProps(1)} />
            
          </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div className="steps-for-you">

            <h4 className="title"><span className="encircled-num">1</span> See what's working</h4>
            <div className="info-text">
              <p className="list-info">Great self-discovery is driven by great questions. Consider the items in the green list and ask yourself:</p>
              <ul>
                <li>What's the strongest item that you can leverage?</li>
                <li>What's lower in rank and could use improvement or further investment?</li>
              </ul>
            </div>
            <h4 className="title"><span className="encircled-num">2</span> See what needs work</h4>
            <div className="info-text">
              <p>Examine the red and yellow lists and highlight the biggest roadblocks. Consider what you would do about them.</p>
            </div>
            <h4 className="title"><span className="encircled-num">3</span> Get your team involved</h4>
            <div className="info-text">
              <p className="list-info">We strongly recommend that you take this assessment as a team.</p>
              <ul>
                <li>You'd have a common metric to evaluate the church so you're on the same page</li>
                <li>You can all see the results in real time</li>
                <li>Walking through the results together will help you arrive at some actionable steps</li>
              </ul>
              <br/>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
          <div className="steps-for-you">
            <h4 className="title"><span className="encircled-num">1</span> Compare notes</h4>
            <div className="info-text">
              <p className="list-info">When you take this assessment as a team, the first order of business for you all is to compare notes.</p>
              <ul>
                <li>What was on everyone's list?</li>
                <li>Did you vote or rank items similarly?</li>
                <li>If not, why did you vote the way you voted?</li>
              </ul>
              <p>As you begin the journey of self-discovery as a team, trust in the power of honest and objective conversations and decisions.</p>
            </div>
            <h4 className="title"><span className="encircled-num">2</span> Highlight what's working</h4>
            <div className="info-text">
              <p className="list-info">Go through the items in the green list and select:</p>
              <ul>
                <li>The strongest item in your lists that you can leverage as a church</li>
                <li>An item that's lower in rank and needs improvement or further investment</li>
              </ul>
            </div>
            <h4 className="title"><span className="encircled-num">3</span> Discuss the roadblocks</h4>
            <div className="info-text">
              <p>Highlight the biggest roadblocks in the red and yellow lists, and discuss possible solutions with your team.</p>
            </div>
            <h4 className="title"><span className="encircled-num">4</span> Create an action plan</h4>
            <div className="info-text">
              <p>Assign a team with a leader to create an action plan for each prioritized item.</p>
              <br/>
            </div>
            </div>
          </TabPanel>
        </Box>

      </div> */}

      {/* {isMember && <div className="last-text">
        <h4>Thank you for taking this assessment!</h4> 
        <p>Please get in touch with your leader for the next step.</p>
      </div>} */}

      {/* {!isMember && showRequestCall()} */}
      {/* {showSchedule()} */}
      {/* {showLegacyInvite()} */}

      {/* </div> */}


      {/*<BookSection />*/}


    </>

  );
};
export default Results;

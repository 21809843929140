import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const Assessment = () => {
  
  const assessCtx           = useContext(AssessmentQuestionContext);
  let navigate              = useNavigate();
  const { id }              = useParams();
  const { windowWidth }     = useWindowDimensions();

  useEffect(() => {
    // document.body.style.backgroundColor = "#343333";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();      
    } else {
      getQuestions();
    }

  }, []);

  const [searchParams, setSearchParams]            = useSearchParams();
  const [isLoading, setIsLoading]                  = useState(true);
  const [activeStep, setActiveStep]                = useState(0);
  const [prevValue, setPrevValue]                  = useState(0);
  const [nextValue, setNextValue]                  = useState(0);
  const [sections, setSections]                    = useState([]);
  const [questions, setQuestions]                  = useState([]);
  const [questionCount, setQuestionCount]          = useState(0);
  const [currentHash, setCurrentHash]              = useState(0);
  const [hasAdditional, setHasAdditional]          = useState(false);
  const [qs, setQs]                                = useState([]);
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        let questions = data.questions;
        // if(retrieveStoredAnswers()) {
        //   let answeredQuestions = [];
        //   let storedQuestions = retrieveStoredAnswers();
        //   questions.forEach(q => {
        //     let question = storedQuestions.find(obj => obj.id == q.id);
        //     if(question) {
        //       q.answerTxt = question.answerTxt;
        //       q.answer = question.answer;
        //       q.answers = question.answers;
        //     }
        //   })
        // }
        // assessCtx.addQuestions(questions);
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        // setHasAdditional(false);
				setHasAdditional(data.hasAdditional);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  function getQuestions() {

    if(assessCtx.questions.length > 0) {
      console.log("assessCtx.questions", assessCtx.questions);
      setQuestions(assessCtx.questions);
      setQuestionCount(assessCtx.questions.length);
      setIsLoading(false);

      // go to the last additional question if coming back from Details page 
      // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
      if(localStorage.getItem("backFromDetailsPage") === "true") {
        setActiveStep(assessCtx.questions.length - 1);
      } else {
        // go to the first unanswered question 
        for (let i = 0; i < assessCtx.questions?.length; i++) {
          if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
            setActiveStep(i);
            break;
          } else setActiveStep(assessCtx.questions.length - 1);
        }
      }

    } else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          let questions = data.questions;
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          assessCtx.addQuestions(questions);
          console.log(data);
          setSections(data.sections);
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          // setHasAdditional(false);
          setHasAdditional(data.hasAdditional);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("error fetching questions", err));
    }
  }

  const currentSectionName = sections?.find(section => section.id === questions[activeStep]?.sectionId)?.section;

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader")) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				if(hasAdditional) {
          navigate("/assessment-additional/" + data.id, { replace: true });
        } else {
          if(data.member == true) {
            navigate("/details/" + data.id + '?member=' + data.memberId, { replace: true });
          } else {
            navigate("/details/" + data.id, { replace: true });
          }
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    if(activeStep < 1) {
      navigate("/start", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    if (activeStep === questions?.length - 1) {
      setTimeout(() => {completeAssessment()}, 500);
    } else if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } else if(currentHash != 0 && hasAdditional) {
      if(searchParams.get("member") != null) {
        navigate("/assessment-additional/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/assessment-additional/" + currentHash, { replace: true });
      }
    } else {
      if(searchParams.get("member") != null) {
        navigate("/details/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/details/" + currentHash, { replace: true });
      }
    }
  };

  const moveToNextQuestion = (answer) => {
    questions[activeStep].answer = answer;
    assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    if(activeStep < questions.length - 1) {
      setTimeout(() => {setActiveStep(activeStep + 1)}, 500);
    } else {
      setTimeout(() => {completeAssessment()}, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory == "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  // console.log("questionsPerSection", questionsPerSection);

  console.log("activeStep", activeStep);

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress  style={{'color': '#F22D33'}} size={60} /></div>
	}

  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div style={{ height: windowWidth > 650 ? "45px" : "10px" }}></div>
            <div className="steps">

              {/* <div className="spacer"></div> */}
              {/* {windowWidth > 830 ? <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} /> : <ProgressBarSingle /> } */}
              <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount}/>
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />  */}
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer"></div> */}
              {/* <p className="current-section">{currentSectionName}</p> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              <div className="spacer mobile-spacer"></div>
              
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div> */}
              <div className="progress-indicator"><strong>{activeStep + 1}</strong></div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button onClick={nextQuestion} style={nextDisabled()}>
                  &nbsp;&nbsp;<img src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;

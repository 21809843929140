import React from 'react';
import { Link } from "react-router-dom";

// import icLogoPng from '../../Assets/Images/ic-logo.png';
// import icLogoMobilePng from '../../Assets/Images/ic-logo-mobile.png';
import logo from '../../Assets/Images/logo1.png';
import logoMobile from '../../Assets/Images/logo.png';
import bleatLogo from '../../Assets/Images/logo-bleat.svg';
import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header>
        <div className="header-wrapper">
          <Link to="/start" className="logo"><img decoding="async" src={logo} className="attachment-full size-full" alt="" loading="lazy" /></Link> 
          
          {/*<div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">ARE YOU DOUBTING YOUR FAITH?</p>
            {/* <p className="logo-subtext">ASSESSMENT</p> 
          </div> */}

          {/*{ windowWidth > 830 &&
            <div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>
          }*/}

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>

      </header>
    </>
  )
}

export default Header;

import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import useWindowDimensions from '../Hooks/useWindowDimensions';

const ProgressBar = props => {
  
  const { windowWidth } = useWindowDimensions();

  let activeColor           = '#2D8D95';
  let passiveColor          = '#a4a4a4';
  let sections              = [];
  let numOfQuestions        = props.count;
  let quesDistribution      = numOfQuestions / props.sections.length;

  props.sections.map((section) => {
    sections.push({name: section.section, style:{color: passiveColor, fontWeight: '400'}, progress: 0})
  })
  
  let progressWidth         = (100 / sections.length) + '%';
  if(window.innerWidth < 650) {
    progressWidth           = '100%';
  }

  sections.map((data, index) => {
    if(props.currentProgress >= (index * quesDistribution) && props.currentProgress < ((index + 1) * quesDistribution)){
      data.progress         = ((props.currentProgress + 1) / quesDistribution) * 100 - index * 100;
      data.style            = { color: activeColor,  fontWeight: '700' };
    } else{
      if(props.currentProgress < (index * quesDistribution)){
        data.progress       = -1;
      } else {
        data.progress       = 101;
      }
      data.style            = { color: passiveColor, fontWeight: '400' };
    }
  })

  let progressPercentage = Math.round(((props?.currentProgress + 1) / 20) * 100);

  // calculate percentage per section per question 
  const calcPercentPerQuestion = () => {
    let questionsPerSection = props?.questionsPerSection;
    let sections = props?.questionsPerSection?.length;
    // 100% divided by number of sections 
    let eachSection = parseFloat((100 / sections).toFixed(2));
    // console.log(eachSection);
    let progress = 0;
    let progressPerQuestionArr = [];
    for (let i = 0; i < sections; i++) {
      progress = eachSection / questionsPerSection[i];
      // console.log(progress);
      for(let j = 0; j < questionsPerSection[i]; j++) {
        progressPerQuestionArr.push(parseFloat(progress.toFixed(2)));
      }
    }

    let accumulator = 0
    progressPerQuestionArr?.forEach((value, index) => {
      if(index <= props?.currentProgress) { accumulator += value; }
      return accumulator;
    })
    // console.log("accumulator", accumulator);
    return Math.round(accumulator);
  }
  const progressPercent = calcPercentPerQuestion();
  console.log(progressPercent);

  // console.log(sections);

  return (
    <div>
      {/* <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercent}% - 3ch)`
      }}>{`${progressPercentage}%`}</p> */}
      
      {/* <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercent}% - 2ch)`
      }}>{`${progressPercentage}%`}</p> */}

      <div className="progress-bar">
        {sections.map((data, idx) => {
          return(
            <div key={data.name || idx} className="bar-section" style={{width: progressWidth}}>
              {(data.progress > 0 && data.progress <= 100)?
                <img style={{marginLeft: (data.progress - 9) + '%', transition: '1s'}}decoding="async" height="30" src="https://md0.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
                : <img style={{marginLeft: (data.progress - 9) + '%', opacity: 0}}decoding="async" height="30" src="https://md0.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
              }
              <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={data.progress} /></div>
              <div className="section-title" style={data.style}>{data.name.toUpperCase()}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
}
export default ProgressBar;
